import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Grow,
  Paper,
  TextField, // Use TextField instead of CustomTextField for MUI v5
  Typography,
} from "@mui/material";
import { CustomTextField } from "components/CustomTextField";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginAsync } from "store/slice/user";
import { urls } from "utils/urls";

export const LoginPage = () => {
  const { error, success } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const onSubmitData = async (data) => {
    dispatch(loginAsync(data));
  };

  return (
    <Box>
      {/* Background image */}
      <img
        src="https://images.pexels.com/photos/46798/the-ball-stadion-football-the-pitch-46798.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" // Replace with your image path
        alt="Sports background image"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: 0.6, // Adjust opacity for desired effect
          zIndex: -1, // Place behind other content
        }}
      />
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          height: "100vh",
        }}
      >
        <Paper
          elevation={3}
          sx={{ p: 4, m: 2, width: "100%", maxWidth: 600, opacity: 0.8 }}
        >
          <Typography
            component="h1"
            variant="h4"
            align="center"
            gutterBottom
            sx={{ fontWeight: "bold", color: "black" }} // Adjust color for readability
          >
            Camps u
          </Typography>
          <form onSubmit={handleSubmit(onSubmitData)}>
            <Grid container spacing={2} mt={4}>
              {error?.detail && (
                <Grid item xs={12}>
                  <Grow in={!!error?.detail}>
                    <Alert severity="error">{error?.detail}</Alert>
                  </Grow>
                </Grid>
              )}
              <Grid item xs={12}>
                <CustomTextField
                  label="Email"
                  required
                  register={register("username")}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Password"
                  type="password"
                  required
                  error={!!error?.detail}
                  helperText={error?.detail || ""}
                  register={register("password")}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  color="primary"
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </form>
          <Link to="/signup">Signup</Link>
        </Paper>
      </Container>
    </Box>
  );
};

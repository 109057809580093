import React from "react";
import { useController } from "react-hook-form";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimeField } from "@mui/x-date-pickers";

const CustomTimeField = ({ name, label, value, onChange, control }) => {
  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue: value || null,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimeField
        {...inputProps}
        label={label}
        inputRef={ref}
        sx={{ width: "100%" }}
        value={inputProps.value || null}
        onChange={(newValue) => onChange(newValue)}
        slotProps={{
          textField: {
            size: "small",
            required: true,
            InputLabelProps: { shrink: true },
          },
        }}
        renderInput={(params) => <TextField required fullWidth {...params} />}
        showTodayButton
      />
    </LocalizationProvider>
  );
};

export default CustomTimeField;

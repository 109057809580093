import { Box, Grid, Typography } from "@mui/material";
import CampCard from "components/CampCard";
import { CustomGrow } from "components/CustomGrow";
import FullPageLoader from "components/FullPageLoader";
import Wrapper from "components/Wrapper";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeGetRequest } from "utils/api";
import { urls } from "utils/urls";

export const FavoriteCamps = () => {
  const [campsList, setCampsList] = useState({});
  const [loading, setLoading] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    fetchCamps();
  }, []);
  const fetchCamps = async () => {
    setLoading(true);
    const response = await makeGetRequest(urls.favourite, {}, isAuthenticated);
    if (response.status === 200) {
      setCampsList(response.data);
    }
    setLoading(false);
  };
  return (
    <Box mt={4}>
      <FullPageLoader loading={loading} />
      <h2 style={{ textAlign: "center" }}>Camps User Favorite/❤️</h2>
      <Grid container justifyContent={"center"} spacing={3} mt={1}>
        {campsList.results?.map((v, index) => (
          <Grid key={v.slug} item xs={12} sm={6} md={4} lg={3}>
            <CustomGrow index={index + 1}>
              <CampCard
                {...v.camp_details}
                favourite={true}
                favPage={true}
                setCampsList={setCampsList}
              />
            </CustomGrow>
          </Grid>
        ))}
      </Grid>
      {campsList.count === 0 && (
        <Typography variant="h6" color="text.secondary">
          Your list is empty.
        </Typography>
      )}
    </Box>
  );
};

import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Grow,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import CustomSelectField from "components/CustomSelectField";
import { CustomTextField } from "components/CustomTextField";
import Form from "components/Form";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { makePostRequest } from "utils/api";
import { urls } from "utils/urls";

export const SignupPage = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [formErrors, setFormErrors] = useState({});
  const [isOrganizer, setIsOrganizer] = useState(false);
  const {
    handleSubmit,
    register,
    control,
    watch,

    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      password2: "",
    },
  });
  const password = useRef({});
  password.current = watch("password", "");

  const onSubmitData = async (data) => {
    const payload = { ...data };
    payload.email = payload.username;
    const response = await makePostRequest(urls.signup, payload, {});
    if (response.status === 201) {
      enqueueSnackbar("Account created successfully", { variant: "success" });
    } else {
      setFormErrors(response.data);
    }
  };
  return (
    <Box>
      <img
        src="https://images.pexels.com/photos/46798/the-ball-stadion-football-the-pitch-46798.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" // Replace with your image path
        alt="Sports background image"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: 0.6, // Adjust opacity for desired effect
          zIndex: -1, // Place behind other content
        }}
      />
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Paper
          elevation={3}
          sx={{ padding: 4, width: "100%", maxWidth: 600, opacity: 0.9 }}
        >
          <Typography
            component="h1"
            variant="h4"
            align="center"
            gutterBottom
            sx={{ fontWeight: "bold" }}
          >
            Signup
          </Typography>
          <Form formProps={{ onSubmit: handleSubmit(onSubmitData) }}>
            <Grid container spacing={2} mt={1}>
              {formErrors.detail && (
                <Grid item xs={12}>
                  <Grow in={formErrors.detail || false}>
                    <Alert severity="info">{formErrors.detail}</Alert>
                  </Grow>
                </Grid>
              )}
              <Grid item xs={12}>
                <CustomTextField
                  label={isOrganizer ? "Company Name" : "First Name"}
                  // margin="normal"
                  required
                  error={!!formErrors.first_name}
                  helperText={!!formErrors.first_name || ""}
                  register={register("first_name")}
                />
              </Grid>
              {!isOrganizer && (
                <Grid item xs={12}>
                  <CustomTextField
                    label="Last Name"
                    // margin="normal"
                    required
                    error={!!formErrors.last_name}
                    helperText={formErrors.last_name || ""}
                    register={register("last_name")}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <CustomTextField
                  label="Email"
                  // margin="normal"
                  required
                  error={!!formErrors.username}
                  helperText={formErrors.username || ""}
                  register={register("username")}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CustomTextField
                  label="Password"
                  type="password"
                  required
                  error={!!formErrors.password}
                  helperText={formErrors.password || ""}
                  register={register("password", {
                    minLength: {
                      value: 8,
                      message: "Minimum Length of password is 8",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CustomTextField
                  label="Confirm Password"
                  type="password"
                  required
                  error={!!formErrors.password2 || !!errors.password2}
                  helperText={
                    formErrors.password2 ||
                    (errors.password2 && errors.password2.message) ||
                    ""
                  }
                  register={register("password2", {
                    required: {
                      value: true,
                      message: "Confirm Password is required",
                    },
                    validate: (value) =>
                      value === password.current || "Passwords do not match",
                    minLength: {
                      value: 8,
                      message: "Minimum Length of password is 8",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomSelectField
                  control={control}
                  margin="dense"
                  required
                  fullWidth
                  name={`gender`}
                  variant="outlined"
                  label="Gender"
                >
                  {["Male", "Female"].map((v, i) => (
                    <MenuItem key={v} value={v}>
                      {v}
                    </MenuItem>
                  ))}
                </CustomSelectField>
              </Grid>

              <Grid item xs={12}>
                <CustomTextField
                  label="Phone Number"
                  // margin="normal"
                  required
                  error={!!formErrors.phone_number}
                  helperText={formErrors.phone_number || ""}
                  register={register("phone_number")}
                />
              </Grid>
              {!isOrganizer && (
                <Grid item xs={12}>
                  <CustomTextField
                    label="Date of birth"
                    // margin="normal"
                    required
                    type="date"
                    error={!!formErrors.date_of_birth}
                    helperText={formErrors.date_of_birth || ""}
                    register={register("dob")}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <CustomTextField
                  label="Address"
                  // margin="normal"
                  error={!!formErrors.address}
                  helperText={formErrors.address || ""}
                  register={register("address")}
                />
              </Grid>

              <Grid item xs={12}>
                <Button type="submit" variant="contained" fullWidth>
                  Signup
                </Button>
              </Grid>
            </Grid>

            <Stack justifyContent={"end"} flexDirection="row" mt={1}>
              <Link
                onClick={() => setIsOrganizer(!isOrganizer)}
                to={`#`}
                style={{ color: "#041684" }}
              >
                Signup as {isOrganizer ? "Camper" : "Organizer"}
              </Link>
            </Stack>
          </Form>
        </Paper>
      </Container>
    </Box>
  );
};

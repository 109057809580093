import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TruncatedText from "components/TruncatedText";
import { Box, CardActionArea, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { makeDeleteRequest, makePostRequest } from "utils/api";
import { urls } from "utils/urls";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { CustomBtn } from "components/CustomBtn";

export default function CampCard({
  main_image,
  title,
  subheading,
  slug,
  id,
  favourite = false,
  setFavouriteCamps = null,
  favPage = false,
  setCampsList = null,
  myCamp = false,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.user);
  const handleFavouriteCamp = async () => {
    if (favourite) {
      const response = await makeDeleteRequest(
        urls.favouriteDelete + "?camp=" + id,
        {},
        isAuthenticated
      );
      if (response.status === 200) {
        if (favPage) {
          setCampsList((prev) => ({
            ...prev,
            results: prev.results.filter((v) => v.camp_details.id != id),
          }));
        } else {
          setFavouriteCamps((prev) => prev.filter((v) => v != id));
        }
      }
    } else {
      const response = await makePostRequest(
        urls.favourite,
        { camp: id },
        {},
        isAuthenticated
      );
      if (response.status === 201) {
        enqueueSnackbar("Camp Added to Favourites Successfully", {
          variant: "success",
        });
        if (setFavouriteCamps) {
          setFavouriteCamps((prev) => [...prev, id]);
        }
      }
    }
  };

  const handleAddToCart = async () => {
    const response = await makePostRequest(
      urls.cart,
      { camp: id },
      {},
      isAuthenticated
    );
    if (response.status === 201) {
      enqueueSnackbar("Camp is added to cart!", { variant: "success" });
    }
  };

  return (
    <Card>
      <CardActionArea
        onClick={() => navigate("/camp/" + slug)}
        sx={{
          "&:hover .title": {
            color: "red", // Change to your desired color
          },
        }}
      >
        <CardMedia sx={{ height: 140 }} image={main_image} title={title} />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className="title"
          >
            {title}
          </Typography>
          <TruncatedText desc={subheading} />
        </CardContent>
      </CardActionArea>
      {!myCamp && (
        <Box display="flex" justifyContent={"space-between"} p={1}>
          <IconButton
            onClick={() => handleFavouriteCamp(id)}
            aria-label="add to favorites"
          >
            <FavoriteIcon color={favourite ? "error" : "action"} />
          </IconButton>
          <CustomBtn name="ADD TO CART" onClick={handleAddToCart} />
        </Box>
      )}
    </Card>
  );
}

import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { useState } from "react";
import { Controller } from "react-hook-form";

export const ControlledAutocomplete = ({
  options = [],
  label,
  getOptionLabel,
  control,
  defaultValue,
  name,
  required,
  fullWidth,
  multiple,
  error,
  helperText,
  loading,
  isOptionEqualToValue,
}) => {
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = useState("");
  const handleOpen = () => {
    if (inputValue.length > 0) {
      setOpen(true);
    }
  };
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          options={options}
          // open={open}
          // onOpen={handleOpen}
          // onClose={() => setOpen(false)}
          onInputChange={handleInputChange}
          multiple={multiple}
          getOptionLabel={getOptionLabel}
          fullWidth={fullWidth || false}
          isOptionEqualToValue={isOptionEqualToValue}
          className="customAutoComplete"
          renderInput={(params) => (
            <TextField
              {...params}
              required={required}
              label={label}
              size="small"
              error={error}
              helperText={helperText}
              InputLabelProps={{ shrink: true }} // Make the label shrink
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onChange={(e, data) => {
            onChange(data);
          }}
          value={value}
        />
      )}
      defaultValue={defaultValue}
      name={name}
      control={control}
      // rules={{ required: { value: true, message: "Please Select Applicant" } }}
    />
  );
};

import Wrapper from "components/Wrapper";
import AttendanceCalendar from "containers/Attendance";
import React from "react";

const users = [
  { id: 1, name: "John Doe" },
  { id: 2, name: "Jane Smith" },
  { id: 3, name: "Alice Johnson" },
];
export const OrganizerDashboard = () => {
  return (
    <Wrapper>
      <h1>Attendance System</h1>
      <AttendanceCalendar
        users={users}
        startDate="2024-05-31"
        endDate="2024-06-30"
      />
    </Wrapper>
  );
};

import React from "react";
import { Grid, Button, IconButton, Box } from "@mui/material";
import { AddPhotoAlternate, CloseOutlined } from "@mui/icons-material";

const ImageUploader = ({
  selectedImages,
  setSelectedImages,
  cover = false,
}) => {
  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    });

    Promise.all(newImages).then((images) => {
      if (cover) {
        setSelectedImages(images.slice(0, 1)); // Only allow one image
      } else {
        setSelectedImages((prevImages) => [...prevImages, ...images]);
      }
    });
  };

  const clearImage = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  return (
    <Grid container spacing={2}>
      {selectedImages.map((image, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="#f5f5f5"
            borderRadius={8}
            p={1}
            position="relative"
          >
            <img
              src={image}
              alt={`Selected ${index}`}
              style={{
                maxWidth: "100%",
                maxHeight: "300px",
                borderRadius: "8px",
              }}
            />
            <IconButton
              aria-label="Clear image"
              onClick={() => clearImage(index)}
              style={{
                position: "absolute",
                top: "4px",
                right: "4px",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
              }}
            >
              <CloseOutlined />
            </IconButton>
          </Box>
        </Grid>
      ))}
      <Grid item xs={12}>
        <label htmlFor={`image-upload-${cover ? "cover" : "more"}`}>
          <input
            id={`image-upload-${cover ? "cover" : "more"}`}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImageChange}
            multiple={!cover}
          />
          <Button
            variant="outlined"
            component="span"
            startIcon={<AddPhotoAlternate />}
            sx={{
              background: "linear-gradient(to right, #434343, #000000)",
              color: "#fff",
              borderRadius: "20px",
              textTransform: "none",
              padding: "10px 20px",
              fontSize: "16px",
              "&:hover": {
                background: "linear-gradient(to right, #000000, #434343)",
              },
            }}
          >
            {cover ? "Upload Cover Image" : "Upload Images"}
          </Button>
        </label>
      </Grid>
    </Grid>
  );
};

export default ImageUploader;

import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import "./style.css";
import { Paper, Box } from "@mui/material";
import { Controller } from "react-hook-form";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    // [{ font: [] }],
    // [{ size: ["small", false, "large", "huge"] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ["link"],
    ["clean"],
  ],
};

const formats = [
  "header",
  // "font",
  // "size",
  "list",
  "bullet",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "script",
  "indent",
  "direction",
  "color",
  "background",
  "align",
  "link",
  "image",
  "video",
];

const ReactQuillBuilder = ({ control, name }) => {
  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Box sx={{ mt: 2 }}>
        <Controller
          name={name}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <ReactQuill
              value={field.value}
              onChange={(content, delta, source, editor) => {
                field.onChange(content);
              }}
              modules={modules}
              formats={formats}
              placeholder="Write something amazing..."
              style={{ height: "600px", marginBottom: "100px" }}
            />
          )}
        />
      </Box>
    </Paper>
  );
};

export default ReactQuillBuilder;

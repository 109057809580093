import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Box,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom"; // Import React Router's Link
import { makeGetRequest } from "utils/api";
import { urls } from "utils/urls";
import "./style.css";
import FullPageLoader from "components/FullPageLoader";

const NewsPage = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchNews();
  }, []);
  const fetchNews = async () => {
    setLoading(true);
    const response = await makeGetRequest(urls.news + "?limit=1000", {}, false);
    if (response.status === 200) {
      setData(response.data.results);
    }
    setLoading(false);
  };
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      {loading && <FullPageLoader />}

      <Box sx={{ mb: 8, textAlign: "center" }}>
        <Typography
          variant="h3"
          fontWeight="bold"
          gutterBottom
          sx={{ color: "#3f51b5" }}
        >
          Latest Camp News & Updates
        </Typography>
        <Typography
          variant="h6"
          color="textSecondary"
          gutterBottom
          sx={{ maxWidth: "600px", mx: "auto" }}
        >
          Discover the latest information about our upcoming camps and outdoor
          events. Stay informed and get ready for the adventure!
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {data.map((article) => (
          <Grid item xs={12} md={4} key={article.id}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                borderRadius: 4,
                boxShadow: 5,
                transition: "transform 0.3s",
                "&:hover": { transform: "scale(1.05)" },
              }}
            >
              <CardMedia
                component="img"
                image={article.image}
                alt={article.title}
                sx={{ height: 250, objectFit: "cover" }}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  gutterBottom
                  sx={{ color: "gray" }}
                >
                  {article.title}
                </Typography>
              </CardContent>
              <Box sx={{ p: 2, textAlign: "center" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  component={Link}
                  to={`/news/${article.id}`} // Dynamic route based on article ID
                  sx={{ borderRadius: 50 }}
                >
                  Learn More
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default NewsPage;

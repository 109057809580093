import { Grow } from "@mui/material";
import React, { useEffect, useRef } from "react";

export const CustomGrow = ({ children, index }) => {
  const elementRef = useRef(null);

  return (
    <Grow in={true} style={{ transformOrigin: "0 0 0" }} timeout={index * 300}>
      <div ref={elementRef}>{children}</div>
    </Grow>
  );
};

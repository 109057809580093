import React, { useEffect, useState } from "react";
import { Container, Typography, Box } from "@mui/material";
import CartItem from "./CartItem";
import Wrapper from "components/Wrapper";
import { makeDeleteRequest, makeGetRequest } from "utils/api";
import { urls } from "utils/urls";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

const CartPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [cartItems, setCartItems] = useState({});
  const { isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    fetchCartItems();
  }, []);
  const fetchCartItems = async () => {
    const response = await makeGetRequest(urls.cart, {}, isAuthenticated);
    if (response.status === 200) {
      setCartItems(response.data);
    }
  };

  const handleRemove = async (id, alert = false) => {
    setCartItems((prevItems) => ({
      ...prevItems,
      results: prevItems.results.filter((item) => item.id !== id),
      count: prevItems.count - 1,
    }));
    const response = await makeDeleteRequest(
      urls.cart + id,
      {},
      isAuthenticated
    );
    if (response.status === 204) {
      if (alert)
        enqueueSnackbar("Camp Deleted Successfully", { variant: "success" });
    }
  };

  return (
    <Wrapper>
      <Box pb={10}>
        <Typography variant="h4" sx={{ my: 4 }}>
          Shopping Cart
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {cartItems.results?.map((item) => (
            <CartItem
              key={item.id}
              item={item.camp_details}
              cartId={item.id}
              onRemove={handleRemove}
            />
          ))}
        </Box>
        {cartItems.count === 0 && (
          <Typography variant="h6" color="text.secondary">
            Your cart is empty.
          </Typography>
        )}
      </Box>
    </Wrapper>
  );
};

export default CartPage;

import React, { useEffect, useState } from "react";
import { Container, Typography, Box } from "@mui/material";
import Wrapper from "components/Wrapper";
import { makeGetRequest } from "utils/api";
import { urls } from "utils/urls";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import OrderedCamp from "./OrderedCamp";
import FullPageLoader from "components/FullPageLoader";

const MyCampOrderPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [orderCamps, setOrderCamps] = useState({});
  const { isAuthenticated } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchOrderCamps();
  }, []);
  const fetchOrderCamps = async () => {
    setLoading(true);
    const response = await makeGetRequest(urls.order, {}, isAuthenticated);
    if (response.status === 200) {
      setOrderCamps(response.data);
    }
    setLoading(false);
  };

  return (
    <Wrapper>
      <FullPageLoader loading={loading} />
      <Box pb={10}>
        <Typography variant="h4" sx={{ my: 4 }}>
          MY CAMPS
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {orderCamps.results?.map((item) => (
            <OrderedCamp
              key={item.id}
              item={item.camp_details}
              cartId={item.id}
              itemDetails={item}
            />
          ))}
        </Box>
        {orderCamps.count === 0 && (
          <Typography variant="h6" color="text.secondary">
            You are not enrolled in any camp.
          </Typography>
        )}
      </Box>
    </Wrapper>
  );
};

export default MyCampOrderPage;

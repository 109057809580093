import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutAsync } from "store/slice/user";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [searchVal, setSearchVal] = React.useState("");
  const [pages, setPages] = React.useState([
    { value: "Camps", route: "/camps" },
    // { value: "Host", route: "/" },
    // { value: "Favourite Camps", route: "/camps/favourite" },
    // { value: "Cart", route: "/cart" },
    { value: "My Camps", route: "/my-camps" },
    { value: "News", route: "/news" },
    { value: "Resources", route: "/resources" },
  ]);
  const [settings, setSettings] = React.useState([
    { name: "Create Camp", route: "/create-camp" },
    { name: "My Camps", route: "/my-camps" },
    { name: "Logout", route: "/" },
  ]);
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (user && user.is_camper) {
      setSettings([{ name: "Logout", route: "/" }]);
    } else if (user && user.is_organizer) {
      setPages([{ value: "Dashboard", route: "/dashboard" }]);
    }
  }, [user]);

  React.useEffect(() => {
    if (!isAuthenticated)
      setPages([
        { value: "Camps", route: "/camps" },
        // { value: "Host", route: "/" },
        // { value: "Favourite Camps", route: "/camps/favourite" },
        // { value: "Cart", route: "/cart" },
        // { value: "My Camps", route: "/my-camps" },
        { value: "News", route: "/news" },
        // { value: "Resources", route: "/resources" },
      ]);
    else
      setPages([
        { value: "Camps", route: "/camps" },
        // { value: "Favourite Camps", route: "/camps/favourite" },
        // { value: "Cart", route: "/cart" },
        { value: "My Camps", route: "/my-camps" },
        { value: "News", route: "/news" },
        // { value: "Resources", route: "/resources" },
      ]);
  }, [isAuthenticated]);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (obj = null) => {
    if (obj) {
      navigate(obj.route);
    }
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (obj) => {
    if (obj.name === "Logout") {
      dispatch(logoutAsync());
    } else {
      navigate(obj.route);
    }
    setAnchorElUser(null);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    navigate("/camps/?search=" + searchVal);
  };

  return (
    <AppBar position="fixed" sx={{ top: 0, zIndex: 1 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            onClick={() => navigate("/")}
            sx={{
              mr: 2,
              cursor: "pointer",
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            CAMPSU
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.value}
                  onClick={() => handleCloseNavMenu(page)}
                >
                  <Typography textAlign="center">{page.value}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.value}
                onClick={() => handleCloseNavMenu(page)}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page.value}
              </Button>
            ))}
          </Box>
          <form onSubmit={handleSearch}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search Camps..."
                onChange={(e) => setSearchVal(e.target.value)}
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </form>

          {isAuthenticated ? (
            <Box sx={{ flexGrow: 0, ml: 1 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={user?.first_name || "A"}
                    src="/static/images/avatar/2.jpg"
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting.name}
                    onClick={() => handleCloseUserMenu(setting)}
                  >
                    <Typography textAlign="center">{setting.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          ) : (
            <Button
              onClick={() => navigate("/login")}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Login
            </Button>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;

import React from "react";
import {
  IconButton,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  CardActionArea,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { CustomBtn } from "components/CustomBtn";
import { makePostRequest } from "utils/api";
import { urls } from "utils/urls";
import { enqueueSnackbar } from "notistack";

const OrderedCamp = ({ item, itemDetails }) => {
  const navigate = useNavigate();
  return (
    <Card sx={{ mb: 2, display: "flex" }}>
      <CardActionArea
        sx={{
          display: "flex",
        }}
        onClick={() => navigate("/camp/" + item.slug)}
      >
        <CardMedia
          component="img"
          sx={{ width: 300 }}
          image={item.main_image}
          alt={item.title}
        />
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1, m: 1 }}>
          <CardContent>
            <Typography variant="h6">{item.title}</Typography>
            <Typography variant="body2" color="text.secondary">
              {item.subheading}
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Price: ${itemDetails.total_price}
            </Typography>
          </CardContent>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default OrderedCamp;

import React from "react";
import { Box, Grid, MenuItem, Typography } from "@mui/material";
import CategoryCard from "components/CategoryCard";
import CategoryCardLoading from "components/CategoryCardLoading";
import { ControlledAutocomplete } from "components/CustomAutoComplete";
import { CustomBtn } from "components/CustomBtn";
import CustomSelectField from "components/CustomSelectField";
import { CustomTextField } from "components/CustomTextField";
import { useForm } from "react-hook-form";
import { AGE_CHOICES } from "utils/constants";
import Form from "components/Form";
import { useNavigate } from "react-router-dom";

const primaryColor = "#001f3f"; // Primary color for sports theme
const secondaryColor = "#e53935"; // Secondary color for accents
const backgroundColor = "#f4f6f8"; // Light background color
const boxShadowColor = "rgba(0, 0, 0, 0.1)"; // Subtle box shadow color

export const FindCampsNearYou = ({ categoriesList, loading }) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      category: {},
      type: "",
      season: "",
      age: "",
    },
  });
  const navigate = useNavigate();

  const handleSubmitData = (data) => {
    const queryParams = new URLSearchParams({
      category: data.category.name || "",
      type: data.type,
      season: data.season,
      age: data.age,
      address: data.address || "",
    }).toString();
    navigate(`/camps/?${queryParams}`);
  };

  return (
    <Box p={3} bgcolor={backgroundColor}>
      <Typography
        variant="h4"
        component="div"
        sx={{
          mb: "2rem",
          textAlign: "center",
          color: primaryColor,
          fontWeight: "bold",
          "@media (max-width: 600px)": {
            fontSize: "1.5rem", // Adjust font size for small screens

            mb: "1rem", // Adjust margin for small screens
          },
        }}
      >
        Find Camps Near You
      </Typography>
      <Form formProps={{ onSubmit: handleSubmit(handleSubmitData) }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} order={{ xs: 1, md: 2 }}>
            <Box
              bgcolor={"#fff"}
              p={2}
              borderRadius={"8px"}
              boxShadow={`0px 4px 10px ${boxShadowColor}`}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{
                  textAlign: "center",
                  backgroundColor: primaryColor,
                  color: "#fff",
                  padding: 2,
                  borderRadius: "8px",
                  mb: 2,
                }}
              >
                Search Filters
              </Typography>
              <ControlledAutocomplete
                control={control}
                name="category"
                loading={false}
                options={categoriesList.results || []}
                getOptionLabel={(option) => option.name || ""} // Customize as needed
                label="Choose Activity"
                isOptionEqualToValue={(option, value) => option.id === value.id} // Customize as needed
                required={true}
                defaultValue={null}
              />
              <CustomSelectField
                control={control}
                required
                fullWidth
                name={`type`}
                sx={{ mt: 2 }}
                variant="outlined"
                label="Choose Type"
              >
                {["Day", "Half Day", "Extended", "Overnight", "Clinic"].map(
                  (v) => (
                    <MenuItem key={v} value={v}>
                      {v}
                    </MenuItem>
                  )
                )}
              </CustomSelectField>
              <CustomSelectField
                control={control}
                required
                fullWidth
                name={`season`}
                sx={{ mt: 2 }}
                variant="outlined"
                label="Season"
              >
                {["Spring", "Summer", "Fall", "Winter"].map((v) => (
                  <MenuItem key={v} value={v}>
                    {v}
                  </MenuItem>
                ))}
              </CustomSelectField>
              <CustomTextField
                sx={{ mt: 2 }}
                label="Age"
                type="number"
                required
                register={register("age")}
              />
              <CustomTextField
                sx={{ mt: 2 }}
                label="Address"
                register={register("address")}
              />
              <CustomBtn
                name="Search"
                sx={{
                  width: "100%",
                  mt: 2,
                  bgcolor: primaryColor,
                  color: "#fff",
                  "&:hover": {
                    bgcolor: secondaryColor,
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={8} order={{ xs: 2, md: 1 }}>
            <Box
              maxHeight={"425px"}
              overflow="auto"
              p={1}
              sx={{
                bgcolor: "#fff",
                borderRadius: "8px",
                boxShadow: `0px 4px 10px ${boxShadowColor}`,
              }}
            >
              <Grid container spacing={2}>
                {loading
                  ? Array(8)
                      .fill(null)
                      .map((_, index) => (
                        <Grid item key={index} xs={6} md={4} lg={3}>
                          <CategoryCardLoading />
                        </Grid>
                      ))
                  : categoriesList.results?.map((category) => (
                      <Grid item key={category.id} xs={6} md={4} lg={3}>
                        <CategoryCard category={category} />
                      </Grid>
                    ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </Box>
  );
};

export default FindCampsNearYou;

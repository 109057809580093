import { Box } from "@mui/material";
import Navbar from "components/AppBar";
import Footer from "components/Footer";
import { CamperDashboard } from "containers/CamperDashboard";
import { CampsPage } from "containers/CampsPage";
import CampCreatePage from "containers/CampsPage/CampCreatePage";
import { CampsDetailPage } from "containers/CampsPage/CampsDetailPage";
import { FavoriteCamps } from "containers/CampsPage/FavouriteCamps";
import CartPage from "containers/CartPage";
import { HomePage } from "containers/HomePage";
import { LoginPage } from "containers/LoginPage";
import MyCampOrderPage from "containers/MyCampOrderPage";
import { MyCampsPage } from "containers/MyCampsPage";
import NewsPage from "containers/News";
import LearnMorePage from "containers/News/LearnMorePage";
import { OrganizerDashboard } from "containers/OrganizerDashboard";
import { SignupPage } from "containers/SignupPage";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  fetchUserAsync,
  logout,
  logoutAsync,
  updateUserAsync,
} from "store/slice/user";
import { makeGetRequest } from "utils/api";
import { urls } from "utils/urls";

function App() {
  const { isAuthenticated, user, success } = useSelector((state) => state.user);
  const is_camper = isAuthenticated && user && user.is_camper;
  const is_organizer = isAuthenticated && user && user.is_organizer;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchUserAsync());
      navigate("/");
    } else {
      dispatch(logoutAsync());
      dispatch(logout());
    }
  }, [isAuthenticated]);

  // useEffect(() => {
  //   if (success) {
  //     navigate("/");
  //   }
  // }, [success]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Navbar />
      <Box sx={{ marginTop: "70px" }}>
        <Routes>
          {!isAuthenticated && [
            <Route key="login" path="/login" element={<LoginPage />} />,
            <Route key="signup" path="/signup" element={<SignupPage />} />,
          ]}

          {is_organizer && [
            <Route
              key="create-camp"
              path="/create-camp"
              element={<CampCreatePage />}
            />,
            <Route
              key="my-camps"
              path="/my-camps/"
              element={<MyCampsPage />}
            />,
            <Route path="/dashboard" index element={<OrganizerDashboard />} />,
          ]}
          <Route path="/camp/:slug" element={<CampsDetailPage />} />
          <Route path="/camps" element={<CampsPage />} />
          {is_camper && [
            <Route
              key="camp-favourite"
              path="/camps/favourite"
              element={<FavoriteCamps />}
            />,
            <Route key="cart" path="/cart" element={<CartPage />} />,
            <Route
              key="my-camps"
              path="/my-camps/"
              element={<MyCampOrderPage />}
            />,
            <Route
              path="/dashboard"
              key="dashboard"
              index
              element={<CamperDashboard />}
            />,
          ]}
          <Route path="/news" element={<NewsPage />} />
          <Route path="/news/:id" element={<LearnMorePage />} />

          <Route path="/" index element={<HomePage />} />
        </Routes>
      </Box>
      <Footer />
    </>
  );
}

export default App;

import { Button } from "@mui/material";
import React from "react";

export const CustomBtn = (props) => {
  return (
    <Button
      variant="contained"
      type="submit"
      sx={{
        backgroundColor: "#333",
        color: "#fff",
        "&:hover": {
          backgroundColor: "#555",
        },
      }}
      {...props}
    >
      {props.name}
    </Button>
  );
};

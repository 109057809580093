import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { makeGetRequest, makePostRequest } from "utils/api";
import { urls } from "utils/urls";

// Define async thunk for login
export const loginAsync = createAsyncThunk(
  "user/login",
  async (credentials, thunkAPI) => {
    const response = await makePostRequest(urls.login, credentials);
    if (response.status === 200) {
      const { access } = response.data;
      localStorage.setItem("token", JSON.stringify(access));
      return access;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

// Define async thunk for logout
export const logoutAsync = createAsyncThunk(
  "user/logout",
  async (_, thunkAPI) => {
    try {
      // Perform any necessary logout API call if needed
      localStorage.removeItem("token");
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Define async thunk for updating user details
export const updateUserAsync = createAsyncThunk(
  "user/updateUser",
  async (userData, thunkAPI) => {
    try {
      const response = await axios.put("/api/user", userData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserAsync = createAsyncThunk(
  "user/fetchUser",
  async (_, thunkAPI) => {
    const response = await makeGetRequest(urls.userDetails, {}, true);
    if (response.status === 200) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

const token = JSON.parse(localStorage.getItem("token"));

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isAuthenticated: !!token,
    token: token || null,
    user: null,
    loading: false,
    error: null,
    success: false,
  },
  reducers: {
    logout: (state) => {
      localStorage.removeItem("token");
      state.isAuthenticated = false;
      state.token = null;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.token = action.payload;
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(logoutAsync.fulfilled, (state) => {
        state.isAuthenticated = false;
        state.token = null;
        state.user = null;
      })
      .addCase(updateUserAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(updateUserAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchUserAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.user = null;
        state.success = false;
      })
      .addCase(fetchUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.success = true;
      })
      .addCase(fetchUserAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = null;
        state.user = null;
        state.isAuthenticated = false;
        state.success = false;
        state.token = null;
      });
  },
});

export default userSlice.reducer;
export const { logout } = userSlice.actions;

import { Box, Grid } from "@mui/material";
import CampCard from "components/CampCard";
import { CustomGrow } from "components/CustomGrow";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeGetRequest } from "utils/api";
import { urls } from "utils/urls";

export const FeaturedCamps = () => {
  const [campsList, setCampsList] = useState({});
  const [loading, setLoading] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    fetchCamps();
  }, []);
  const fetchCamps = async () => {
    setLoading(true);
    const response = await makeGetRequest(urls.favourite, {}, isAuthenticated);
    if (response.status === 200) {
      setCampsList(response.data);
    }
    setLoading(false);
  };
  return (
    <Box p={3} bgcolor={"whitesmoke"}>
      <h1 style={{ textAlign: "center", fontWeight: 1000 }}>
        Featured Sports Camps
      </h1>
      <Grid container justifyContent={"center"} spacing={3} mt={1}>
        {campsList.results?.map((v, index) => (
          <Grid key={v.slug} item xs={12} sm={6} md={4} lg={3}>
            <CustomGrow index={index + 1}>
              <CampCard {...v.camp_details} myCamp />
            </CustomGrow>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

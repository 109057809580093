import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Typography, Box, CardMedia, Button } from "@mui/material";
import { makeGetRequest } from "utils/api";
import { urls } from "utils/urls";
import "./style.css";
import FullPageLoader from "components/FullPageLoader";

const LearnMorePage = () => {
  const { id } = useParams(); // Get the article ID from the URL
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const fetchNewsDetail = async () => {
    setLoading(true);
    const response = await makeGetRequest(urls.news + id, {}, false);
    if (response.status === 200) {
      setData(response.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchNewsDetail();
  }, []);

  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      {loading && <FullPageLoader />}
      <Box
        sx={{
          textAlign: "center",
          mb: 6,
          backgroundColor: "white",
          borderRadius: "10px",
        }}
        p={2}
      >
        <Typography
          variant="h3"
          fontWeight="bold"
          gutterBottom
          sx={{ color: "#3f51b5" }}
        >
          {data.title}
        </Typography>
        <hr />
        <CardMedia
          component="img"
          image={data.image}
          alt={data.title}
          sx={{
            borderRadius: 4,
            boxShadow: 3,
            mb: 4,
            width: "100%", // Set width to take the full width of the container
            maxWidth: "100%", // Ensure it doesn't exceed the screen width
            maxHeight: "50vh", // Limit height to 50% of the viewport height
            objectFit: "cover", // Maintain the aspect ratio and cover the container
          }}
        />
        <Typography
          variant="body1"
          color="textSecondary"
          paragraph
          className="quill-content"
          dangerouslySetInnerHTML={{ __html: data.description }}
        />

        <Button
          variant="contained"
          color="primary"
          size="large"
          href="/news"
          sx={{ borderRadius: 50 }}
        >
          Back to News
        </Button>
      </Box>
    </Container>
  );
};

export default LearnMorePage;

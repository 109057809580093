import { useForm } from "react-hook-form";
import {
  Button,
  Container,
  Grid,
  MenuItem,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import { CustomTextField } from "components/CustomTextField";
import ReactQuillBuilder from "components/ReactQuillBuilder";
import Wrapper from "components/Wrapper";
import { ControlledAutocomplete } from "components/CustomAutoComplete";
import CustomSelectField from "components/CustomSelectField";
import { makeGetRequest, makePostRequest } from "utils/api";
import { urls } from "utils/urls";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ImageUploader from "components/ImageUploader";
import SearchTagInput from "components/SearchTagInput";
import DatePickerTimeZone from "components/DatePicker";
import Form from "components/Form";
import { CustomBtn } from "components/CustomBtn";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomTimeField from "components/TimeField";
import MyCalendar from "components/Calander";
import { usStates } from "utils/constants";
import { formatDate, formatTime } from "utils/helpers";

const CampCreatePage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated } = useSelector((state) => state.user);
  const [categorys, setCategorys] = useState({});
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedCoverImages, setSelectedCoverImages] = useState([]);

  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      category: "",
      start_date: "",
      end_date: "",
      start_time: "",
      end_time: "",
    },
  });

  useEffect(() => {
    fetchCategorys();
  }, []);

  const fetchCategorys = async () => {
    setLoading(true);
    const response = await makeGetRequest(
      urls.category + "?limit=1000",
      {},
      isAuthenticated
    );
    if (response.status === 200) {
      setCategorys(response.data);
    }
    setLoading(false);
  };

  const onSubmit = async (data) => {
    setFormLoading(true);
    const payload = { ...data };
    if (selectedCoverImages.length > 0) {
      payload.main_image = selectedCoverImages[0];
    }
    if (selectedImages.length > 0) {
      payload.optional_images = selectedImages;
    }
    payload.category = data.category.id;
    if (payload.tags) {
      payload.tags = payload.tags.join(",");
    }
    payload.start_date = formatDate(payload.start_date.$d);
    payload.end_date = formatDate(payload.end_date.$d);
    payload.start_time = formatTime(payload.start_time.$d);
    payload.end_time = formatTime(payload.end_time.$d);

    const response = await makePostRequest(
      urls.camp,
      payload,
      {},
      isAuthenticated
    );
    if (response.status === 201) {
      enqueueSnackbar("Camp created successfully", { variant: "success" });
      navigate("/dashboard");
    } else {
      setFormErrors(response.data);
    }
    setFormLoading(false);
  };

  return (
    <Wrapper>
      <Container
        maxWidth="md"
        sx={{
          "@media (max-width: 800px)": {
            p: 0, // Adjust font size for small screens
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            backgroundColor: "#f0f0f0",
            py: 2,
            px: 1,
          }}
        >
          <Paper
            elevation={3}
            sx={{
              padding: 3,
              borderRadius: 2,
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#fff",
              width: "100%",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              align="center"
              sx={{
                marginBottom: 3,
                color: "#333",
                fontWeight: "bold",
                textShadow: "1px 1px 2px rgba(0,0,0,0.1)",
              }}
            >
              Create Camp
            </Typography>
            <Form
              formProps={{ onSubmit: handleSubmit(onSubmit) }}
              isLoading={formLoading}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    label="Title"
                    required
                    register={register("title")}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ControlledAutocomplete
                    control={control}
                    name="category"
                    loading={loading}
                    options={categorys.results || []}
                    getOptionLabel={(option) => `${option.name || ""}`}
                    label="Category"
                    isOptionEqualToValue={(option, value) =>
                      value.name === option.name
                    }
                    required={true}
                    defaultValue={null}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomSelectField
                    control={control}
                    required
                    fullWidth
                    name={`type`}
                    variant="outlined"
                    label="Camp Type"
                  >
                    {["Day", "Half Day", "Extended", "Overnight", "Clinic"].map(
                      (v, i) => (
                        <MenuItem key={v} value={v}>
                          {v}
                        </MenuItem>
                      )
                    )}
                  </CustomSelectField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomSelectField
                    control={control}
                    required
                    fullWidth
                    name={`season`}
                    variant="outlined"
                    label="Season"
                  >
                    {["Spring", "Summer", "Fall", "Winter"].map((v, i) => (
                      <MenuItem key={v} value={v}>
                        {v}
                      </MenuItem>
                    ))}
                  </CustomSelectField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledAutocomplete
                    control={control}
                    name="state"
                    loading={false}
                    options={usStates}
                    getOptionLabel={(option) => `${option}`}
                    label="State"
                    isOptionEqualToValue={(option, value) => value == option}
                    required={true}
                    defaultValue={null}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    label="City"
                    required
                    register={register("city")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    label="Address"
                    required
                    register={register("address")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    label="Location"
                    required
                    register={register("location")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    label="Zip Code"
                    required
                    register={register("zip_code")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePickerTimeZone
                    name="start_date"
                    label="Start Date"
                    value={null} // Set initial value if needed
                    onChange={(value) => setValue("start_date", value)}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePickerTimeZone
                    name="end_date"
                    label="End Date"
                    value={null} // Set initial value if needed
                    onChange={(value) => setValue("end_date", value)}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTimeField
                    name="start_time"
                    label="Start Time"
                    value={null} // Set initial value if needed
                    onChange={(value) => setValue("start_time", value)}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTimeField
                    name="end_time"
                    label="End Time"
                    value={null} // Set initial value if needed
                    onChange={(value) => setValue("end_time", value)}
                    control={control}
                  />
                </Grid>

                {/* <Grid item xs={12} sm={6}>
                  <CustomSelectField
                    control={control}
                    required
                    fullWidth
                    name={`is_private`}
                    variant="outlined"
                    label="Camp Type"
                  >
                    {[
                      { name: "Public", value: false },
                      { name: "Private", value: true },
                    ].map((v, i) => (
                      <MenuItem key={v.name} value={v.value}>
                        {v.name}
                      </MenuItem>
                    ))}
                  </CustomSelectField>
                </Grid> */}

                <Grid item xs={12} sm={6}>
                  <SearchTagInput control={control} name="tags" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    label="Sub Heading"
                    register={register("subheading")}
                    multiline
                    maxRows={7}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    label="Promo Code (if any)"
                    register={register("promo")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    label="Cost"
                    required
                    register={register("cost")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 600 }}
                    gutterBottom
                  >
                    Cover Image Upload
                  </Typography>
                  {/* Add file upload input */}
                  <ImageUploader
                    cover={true}
                    selectedImages={selectedCoverImages}
                    setSelectedImages={setSelectedCoverImages}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 600 }}
                    gutterBottom
                  >
                    Upload More Images (Optional)
                  </Typography>
                  {/* Add file upload input */}
                  <ImageUploader
                    selectedImages={selectedImages}
                    setSelectedImages={setSelectedImages}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 600, textAlign: "center" }}
                    gutterBottom
                  >
                    Detail Info
                  </Typography>
                  <ReactQuillBuilder name="detail_info" control={control} />
                </Grid>

                <Grid item xs={12}>
                  <Box display={"flex"} justifyContent={"end"}>
                    <CustomBtn name="Submit" />
                  </Box>
                </Grid>
              </Grid>
            </Form>
          </Paper>
        </Box>
      </Container>
    </Wrapper>
  );
};

export default CampCreatePage;

import React from "react";
import { Typography } from "@mui/material";

const TruncatedText = ({ desc, variant = "body2", line = 4 }) => {
  return (
    <Typography
      variant={variant}
      color="text.secondary"
      sx={{
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: line,
        overflow: "hidden",
        textOverflow: "ellipsis",

        "@media (max-width: 800px)": {
          fontSize: "0.7rem", // Adjust font size for small screens
        },
      }}
    >
      {desc}
    </Typography>
  );
};

export default TruncatedText;

export const urls = {
  login: `/user/token/`,
  signup: `/user/signup/`,
  userDetails: `/user/details/`,
  camp: `/camp/`,
  category: "/camp/category/",
  reviews: `/camp/reviews/`,
  favourite: `/camp/favourite/`,
  favouriteMin: `/camp/favourite-min/`,
  favouriteDelete: `/camp/favourite/delete/`,
  cart: `/camp/cart/`,
  order: `/camp/order/`,
  camperDashboard: `/camp/camper-dashboard/`,
  attendance: `/camp/attendance/`,
  news: `/camp/news/`,
};

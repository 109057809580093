import { TextField } from "@mui/material";
import React from "react";

export const CustomTextField = (props) => {
  return (
    <TextField
      InputLabelProps={{ shrink: true }}
      size="small"
      fullWidth
      color="secondary"
      variant="outlined"
      // margin={"dense"}
      {...props}
      //   style={{ backgroundColor: "#f6f6f6" }}
      {...props.register}
    />
  );
};

import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const getAuthToken = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

const makeGetRequest = (url, config = {}, withToken = false) => {
  const tokenHeaders = withToken ? getAuthToken() : null;

  return axios
    .get(API_URL + url, {
      ...config,
      ...tokenHeaders,
    })
    .then((response) => response)
    .catch((error) => error.response);
};

function makePostRequest(url, bodyFormData, config = {}, withToken = false) {
  const tokenHeaders = withToken ? getAuthToken() : null;
  return axios
    .post(API_URL + url, bodyFormData, {
      ...config,
      ...tokenHeaders,
    })
    .then((response) => response)
    .catch((error) => error.response);
}

function makePatchRequest(url, bodyFormData, config = {}, withToken = false) {
  const tokenHeaders = withToken ? getAuthToken() : null;

  return axios
    .patch(API_URL + url, bodyFormData, {
      ...config,
      ...tokenHeaders,
    })
    .then((response) => response)
    .catch((error) => error.response);
}

function makeDeleteRequest(url, config = {}, withToken = false) {
  const tokenHeaders = withToken ? getAuthToken() : null;

  return axios
    .delete(API_URL + url, {
      ...config,
      ...tokenHeaders,
    })
    .then((response) => response)
    .catch((error) => error.response);
}

function makePutRequest(url, bodyFormData, config = {}, withToken = false) {
  const tokenHeaders = withToken ? getAuthToken() : null;

  return axios
    .put(API_URL + url, bodyFormData, {
      ...config,
      ...tokenHeaders,
    })
    .then((response) => response)
    .catch((error) => error.response);
}
export {
  makeGetRequest,
  makePostRequest,
  makePatchRequest,
  makePutRequest,
  makeDeleteRequest,
};

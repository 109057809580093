import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import TruncatedText from "components/TruncatedText";
import { useMediaQuery } from "@mui/material";

const CoachesCard = ({ coach }) => {
  const { picture, name, role, bio, baseline } = coach;
  const isSmallScreen = useMediaQuery("(max-width:850px)");

  return (
    <Card
      sx={{
        boxShadow: 3,
        display: "flex",
        "@media (max-width: 800px)": {
          padding: 0, // Adjust font size for small screens
        },
      }}
    >
      <CardMedia
        component="img"
        height="180"
        sx={{
          maxWidth: 150,

          "@media (max-width: 800px)": {
            fontSize: "1rem", // Adjust font size for small screens
            maxWidth: "120px",
            height: "140px",
          },
        }}
        image={picture}
        alt={`${name}'s picture`}
      />
      <CardContent
        sx={{
          "@media (max-width: 800px)": {
            py: 0, // Adjust margin for small screens
          },
        }}
      >
        <Typography
          variant="h5"
          sx={{
            "@media (max-width: 800px)": {
              mt: 1,
              fontSize: "1rem", // Adjust font size for small screens
            },
          }}
          component="div"
        >
          {name}
        </Typography>
        <Typography
          sx={{
            "@media (max-width: 800px)": {
              fontSize: "0.8rem", // Adjust font size for small screens
            },
          }}
          variant="subtitle1"
          color="text.secondary"
        >
          {role}
        </Typography>
        <TruncatedText desc={baseline} variant="body2" line={3} />
        {!isSmallScreen && (
          <Box mt={1}>
            <TruncatedText desc={bio} variant="body1" line={3} />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default CoachesCard;

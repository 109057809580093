// src/components/FullPageLoader.js
import React from "react";
import { Backdrop, CircularProgress, Typography, Box } from "@mui/material";

const FullPageLoader = ({ loading, message = "Loading..." }) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <CircularProgress color="inherit" />
        {message && (
          <Typography variant="h6" sx={{ mt: 2 }}>
            {message}
          </Typography>
        )}
      </Box>
    </Backdrop>
  );
};

export default FullPageLoader;

import React from "react";
import { Autocomplete, TextField, Chip } from "@mui/material";
import { Controller } from "react-hook-form";

const SearchTagsInput = ({ control, name }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          multiple
          size="small"
          id="tags-input"
          options={[]} // Your options array goes here
          freeSolo // Allow the user to enter custom values
          value={value}
          onChange={(event, newValue) => {
            onChange(newValue);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip key={index} label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              size="small"
              {...params}
              variant="outlined"
              label="Search tags"
              InputLabelProps={{ shrink: true }} // Make the label shrink
            />
          )}
        />
      )}
    />
  );
};

export default SearchTagsInput;

import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import "./style.css";

const modules = {
  toolbar: false, // Disable the toolbar
};
export const ReadOnlyQuill = ({ text }) => {
  return (
    <div className="read-only-quill">
      <ReactQuill value={text} readOnly={true} modules={modules} theme="snow" />
    </div>
  );
};

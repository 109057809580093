// src/components/AttendanceCalendar.js
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  useTheme,
  Box,
  Tooltip,
} from "@mui/material";
import { addDays, format } from "date-fns";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import "./style.css";
import { makeGetRequest, makePostRequest } from "utils/api";
import { urls } from "utils/urls";
import { CustomBtn } from "components/CustomBtn";
import { useSnackbar } from "notistack";
import { ControlledAutocomplete } from "components/CustomAutoComplete";
import { useForm } from "react-hook-form";

const AttendanceCalendar = ({ users }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [campList, setCampsList] = useState({});
  const [attendance, setAttendance] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const theme = useTheme();
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      camp: {},
    },
  });
  useEffect(() => {
    fetchCampsList();
  }, []);

  const fetchAttendance = async () => {
    const response = await makeGetRequest(
      urls.attendance + "?camp=" + watch("camp").id,
      {},
      true
    );
    if (response.status === 200) {
      setAttendance(response.data);
      const newDateRange = [];
      for (
        let date = new Date(response.data.start_date);
        date <= new Date(response.data.end_date);
        date = addDays(date, 1)
      ) {
        newDateRange.push(date);
      }
      setDateRange(newDateRange);
    }
  };

  const fetchCampsList = async () => {
    const response = await makeGetRequest(
      urls.camp + "?attendance=1",
      {},
      true
    );
    if (response.status === 200) {
      setCampsList(response.data);
    }
  };

  const handleAttendanceChange = (email, date, status) => {
    setAttendance((prevData) => {
      const updatedAttendance = prevData.attendance.map((user) => {
        if (user.email === email) {
          return {
            ...user,
            attendance: {
              ...user.attendance,
              [date]: status,
            },
          };
        }
        return user;
      });
      return { ...prevData, attendance: updatedAttendance };
    });
  };

  const handleSubmitAttendance = async () => {
    const response = await makePostRequest(
      urls.attendance,
      attendance,
      {},
      true
    );
    if (response.status === 201) {
      enqueueSnackbar("Attendance Marked Successfully", { variant: "success" });
    }
  };

  const handleFilterCamp = async () => {
    if (watch("camp")) {
      fetchAttendance();
    }
  };
  return (
    <Box bgcolor={"whitesmoke"} p={2}>
      <Box justifyContent={"end"} display={"flex"}>
        <Box
          display={"flex"}
          p={1}
          justifyContent={"space-between"}
          width="350px"
          sx={{
            background: "white",
            borderRadius: "10px",
            marginBottom: "10px",
          }}
        >
          <ControlledAutocomplete
            control={control}
            name="camp"
            fullWidth
            loading={false}
            options={campList.results || []}
            getOptionLabel={(option) =>
              `${option.title || ""}  ${option.id ? "| " + option.id : ""}`
            }
            label="CAMPS"
            isOptionEqualToValue={(option, value) => value.id === option.id}
            defaultValue={null}
          />
          <CustomBtn name="Filter" sx={{ ml: 1 }} onClick={handleFilterCamp} />
        </Box>
      </Box>
      <TableContainer component={Paper} className="tableContainer">
        <Table sx={{ minWidth: 650 }} aria-label="attendance table">
          <TableHead>
            <TableRow className="tableHead">
              <TableCell className="tableHeadCell stickyColumn">Name</TableCell>
              {dateRange.map((date) => (
                <TableCell key={date} align="center" sx={{ color: "white" }}>
                  {format(date, "dd-MM-yyyy")}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {attendance.attendance?.map((user, index) => (
              <TableRow key={user.email} className="tableRow">
                <TableCell component="th" scope="row" className="stickyColumn">
                  {user.fullname}
                </TableCell>
                {dateRange.map((date) => (
                  <TableCell key={date} align="center">
                    <Tooltip title="Present">
                      <IconButton
                        color={
                          user.attendance[format(date, "yyyy-MM-dd")] ===
                          "present"
                            ? "success"
                            : "default"
                        }
                        onClick={() =>
                          handleAttendanceChange(
                            user.email,
                            format(date, "yyyy-MM-dd"),
                            "present"
                          )
                        }
                      >
                        <CheckCircleIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Absent">
                      <IconButton
                        color={
                          user.attendance[format(date, "yyyy-MM-dd")] ===
                          "absent"
                            ? "error"
                            : "default"
                        }
                        onClick={() =>
                          handleAttendanceChange(
                            user.email,
                            format(date, "yyyy-MM-dd"),
                            "absent"
                          )
                        }
                      >
                        <CancelIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display={"flex"} justifyContent={"end"}>
        <CustomBtn
          onClick={handleSubmitAttendance}
          name="Save"
          sx={{ mt: 1 }}
        />
      </Box>
    </Box>
  );
};

export default AttendanceCalendar;

import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Grid,
  Box,
  Rating,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { CustomTextField } from "components/CustomTextField";
import { makePostRequest } from "utils/api";
import { urls } from "utils/urls";
import { useSnackbar } from "notistack";
import Form from "components/Form";
import { CustomBtn } from "components/CustomBtn";
import { useSelector } from "react-redux";

const ReviewList = ({ reviews, camp, setReviews }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.user);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      review: "",
      rating: "",
    },
  });

  const handleSubmitReview = async (data) => {
    const response = await makePostRequest(
      urls.reviews,
      { camp: camp, review: data.review, rating: data.rating },
      {},
      true
    );
    if (response.status === 201) {
      setReviews((prev) => ({
        ...prev,
        results: [...prev.results, response.data],
      }));
      enqueueSnackbar("Thanks for submitting review!", { variant: "success" });
    }
  };
  const isReviewExists = () => {
    if (user && user.id) {
      return reviews.results?.some((review) => review.camper_id === user.id);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, mb: 2 }}>
      {!isReviewExists() && (
        <>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              "@media (max-width: 800px)": {
                fontSize: "1rem", // Adjust font size for small screens
                mb: 2,
                textAlign: "center",
              },
            }}
          >
            Submit a Review
          </Typography>
          <Form formProps={{ onSubmit: handleSubmit(handleSubmitReview) }}>
            <CustomTextField
              label="Write a review"
              required
              register={register("review")}
              error={!!errors.review}
              helperText={errors.review ? "Review is required" : ""}
              sx={{ mb: 2 }}
            />
            <Controller
              name="rating"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Rating
                  {...field}
                  sx={{
                    mb: 2,
                  }}
                />
              )}
            />
            <CustomBtn type="submit" name="Submit" fullWidth />
          </Form>
        </>
      )}

      <Typography
        variant="h5"
        gutterBottom
        sx={{
          mt: 4,
          "@media (max-width: 800px)": {
            fontSize: "1rem", // Adjust font size for small screens
          },
        }}
      >
        Reviews:
      </Typography>
      <Grid container spacing={2}>
        {reviews.results?.map((review, index) => (
          <Grid item xs={12} key={index}>
            <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
              <CardContent>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Avatar sx={{ bgcolor: "primary.main" }}>
                      {review.camper_name.charAt(0)}
                    </Avatar>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="h6"
                      sx={{
                        "@media (max-width: 800px)": {
                          fontSize: "1rem", // Adjust font size for small screens
                        },
                      }}
                      component="div"
                    >
                      {review.camper_name}
                    </Typography>
                    <Rating
                      name="half-rating-read"
                      defaultValue={review.rating}
                      precision={0.5}
                      readOnly
                      sx={{
                        mb: 1,
                      }}
                    />
                    <Typography variant="body2" color="text.secondary">
                      {review.review}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ReviewList;

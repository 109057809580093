import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { makeGetRequest } from "utils/api";
import { urls } from "utils/urls";
import {
  Box,
  Typography,
  Divider,
  Grid,
  Card,
  CardMedia,
  Modal,
  Backdrop,
  Fade,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { CustomGrow } from "components/CustomGrow";
import Wrapper from "components/Wrapper";
import FullPageLoader from "components/FullPageLoader";
import { ReadOnlyQuill } from "components/ReadOnlyQuill";
import CoachesCard from "./CoachesCard";
import ReviewList from "components/Reviews";
import { CustomTextField } from "components/CustomTextField";
import { formattedTime } from "utils/helpers";
export const CampsDetailPage = () => {
  const { slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [campDetails, setCampDetails] = useState({});
  const [reviews, setReviews] = useState({});

  const { isAuthenticated } = useSelector((state) => state.user);
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleOpenModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  useEffect(() => {
    fetchCampDetails();
    fetchReviews();
  }, []);
  const fetchCampDetails = async () => {
    setLoading(true);
    const response = await makeGetRequest(
      urls.camp + slug,
      {},
      isAuthenticated
    );
    if (response.status === 200) {
      setCampDetails(response.data);
    }
    setLoading(false);
  };

  const fetchReviews = async () => {
    const response = await makeGetRequest(urls.reviews + "?camp=" + slug);
    if (response.status === 200) {
      setReviews(response.data);
    }
  };
  return (
    <>
      <FullPageLoader loading={loading} message={"Loading..."} />
      <Box
        sx={{
          margin: "0 auto",
          bgcolor: "whitesmoke",
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Card sx={{ position: "relative" }}>
          <CardMedia
            component="img"
            image={campDetails.main_image || "/blankImage.jpg"}
            alt="Camp cover image"
            sx={{
              objectFit: "cover",
              height: {
                xs: "35vh", // 50% of viewport height on extra-small screens
                sm: "40vh", // 60% of viewport height on small screens
                md: "50vh", // 70% of viewport height on medium screens
                lg: "60vh", // 80% of viewport height on large screens
              },
            }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 2,
              left: 2,
              bgcolor: "rgba(0, 0, 0, 0.5)",
              p: 1,
              borderRadius: 1,
            }}
          >
            <Typography
              variant="h3"
              component="h1"
              sx={{
                color: "white",
                fontWeight: "bold",
                "@media (max-width: 800px)": {
                  fontSize: "1.5rem", // Adjust font size for small screens

                  mb: "1rem", // Adjust margin for small screens
                },
              }}
            >
              {campDetails.title}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{ color: "white", mt: 1 }}
            >
              {campDetails.reviews} reviews
            </Typography>
          </Box>
        </Card>
        <Wrapper>
          <Box sx={{ p: { xs: 2, md: 4 }, mt: 0 }}>
            {campDetails.images?.length > 0 && (
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 60,
                  "@media (max-width: 800px)": {
                    fontSize: "1.5rem", // Adjust font size for small screens
                    textAlign: "center",
                    mb: "1rem", // Adjust margin for small screens
                  },
                }}
                component="h2"
                gutterBottom
              >
                Images
              </Typography>
            )}
            <Grid container spacing={1}>
              {campDetails.images?.map((image, index) => (
                <Grid item xs={4} sm={3} md={2} key={index}>
                  <CustomGrow index={index + 1}>
                    <Card
                      sx={{
                        boxShadow: 3,
                        borderRadius: 2,
                        cursor: "pointer",
                        overflow: "hidden",
                        position: "relative",
                        "&:hover img": {
                          transform: "scale(1.1)", // Zoom effect on hover
                        },
                      }}
                      onClick={() => handleOpenModal(image.image)}
                    >
                      <CardMedia
                        component="img"
                        height="100"
                        image={image.image}
                        alt={`Camp image ${index + 1}`}
                        sx={{
                          borderRadius: 2,
                          transition: "transform 0.3s ease-in-out",
                        }} // Smooth transition for zoom effect
                      />
                    </Card>
                  </CustomGrow>
                </Grid>
              ))}
            </Grid>

            {/* <Typography variant="body1" sx={{ mt: 1, lineHeight: 1.7 }}>
              {campDetails.subheading}
            </Typography> */}
            <Divider sx={{ my: 3 }} />
            <Box
              bgcolor={"#fff"}
              borderRadius={"8px"}
              boxShadow={`0px 4px 10px rgba(0, 0, 0, 0.1)`}
              p={2}
            >
              <ReadOnlyQuill text={campDetails.detail_info} />
            </Box>
            <Divider sx={{ my: 3 }} />

            <Box
              mt={2}
              borderRadius={"8px"}
              p={2}
              mb={3}
              bgcolor={"#fff"}
              boxShadow={`0px 4px 10px rgba(0, 0, 0, 0.1)`}
            >
              <Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 600,
                    "@media (max-width: 800px)": {
                      fontSize: "1.2rem", // Adjust font size for small screens
                      textAlign: "center",
                      mb: "1rem", // Adjust margin for small screens
                    },
                  }}
                  component="h2"
                  gutterBottom
                >
                  2024 CAMP INFORMATION
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <b>Age Group:</b> {campDetails.age_group}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <b>Camp Type:</b> {campDetails.type}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} style={{ display: "flex" }}>
                  <Typography
                    variant="body1"
                    sx={{
                      borderRight: "1px solid #ccc",
                      paddingRight: "10px", // Adjust spacing as needed
                      marginRight: "10px", // Adjust spacing as needed
                    }}
                  >
                    <b>Start Date:</b> {campDetails.start_date}
                  </Typography>
                  <Typography variant="body1">
                    <b>Start Time:</b> {formattedTime(campDetails.start_time)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} style={{ display: "flex" }}>
                  <Typography
                    variant="body1"
                    sx={{
                      borderRight: "1px solid #ccc",
                      paddingRight: "10px", // Adjust spacing as needed
                      marginRight: "10px", // Adjust spacing as needed
                    }}
                  >
                    <b>End Date:</b> {campDetails.end_date}
                  </Typography>
                  <Typography variant="body1">
                    <b>End Time:</b> {formattedTime(campDetails.end_time)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <b>Check-In:</b> 30 minutes prior to camp start time
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <b>Meals:</b> Lunch is not provided. Please bring a
                    snack/lunch to camp each day.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ my: 3 }} />

            <Box
              borderRadius={"8px"}
              p={2}
              mb={3}
              bgcolor={"#fff"}
              boxShadow={`0px 4px 10px rgba(0, 0, 0, 0.1)`}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 600,
                  "@media (max-width: 800px)": {
                    fontSize: "1.2rem", // Adjust font size for small screens
                    textAlign: "center",
                    mb: "1rem", // Adjust margin for small screens
                  },
                }}
                component="h2"
                gutterBottom
              >
                CAMP COACHES!
              </Typography>
              <Grid container spacing={4}>
                {campDetails.coaches?.map((coach) => (
                  <Grid item xs={12} key={coach.name}>
                    <CoachesCard coach={coach} />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Divider sx={{ my: 3 }} />

            <Box
              borderRadius={"8px"}
              p={2}
              mb={5}
              bgcolor={"#fff"}
              boxShadow={`0px 4px 10px rgba(0, 0, 0, 0.1)`}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 600,
                  "@media (max-width: 800px)": {
                    fontSize: "1.2rem", // Adjust font size for small screens
                    textAlign: "center",
                    mb: "1rem", // Adjust margin for small screens
                  },
                }}
                component="h2"
                gutterBottom
              >
                CAMP REVIEWS!
              </Typography>

              <ReviewList
                camp={campDetails.id}
                setReviews={setReviews}
                reviews={reviews}
              />
            </Box>
            <Box
              borderRadius={"8px"}
              p={2}
              bgcolor={"#fff"}
              boxShadow={`0px 4px 10px rgba(0, 0, 0, 0.1)`}
            >
              {campDetails.videos?.length > 0 && (
                <Box>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 600,
                      "@media (max-width: 800px)": {
                        fontSize: "1.2rem", // Adjust font size for small screens
                        textAlign: "center",
                        mb: "1rem", // Adjust margin for small screens
                      },
                    }}
                    component="h2"
                    gutterBottom
                  >
                    VIDEOS!
                  </Typography>
                </Box>
              )}
              <Grid container spacing={2}>
                {campDetails.videos?.map((video, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <video width="100%" height="200" controls>
                        <source
                          src={video.video}
                          type={`video/${video.video.split(".").pop()}`}
                        />
                        Your browser does not support the video tag.
                      </video>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>

          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="image-modal-title"
            aria-describedby="image-modal-description"
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={openModal}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  maxWidth: "90%",
                  maxHeight: "90%",
                  bgcolor: "white",
                  boxShadow: 24,
                  p: 2,
                }}
              >
                <img
                  src={selectedImage}
                  alt="Enlarged Camp Image"
                  style={{ width: "100%", height: "auto" }}
                />
              </Box>
            </Fade>
          </Modal>
        </Wrapper>
      </Box>
    </>
  );
};

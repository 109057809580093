import React from "react";
import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  IconButton,
} from "@mui/material";
import { Facebook, Twitter, Instagram, LinkedIn } from "@mui/icons-material";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "primary.main",
        color: "white",
        py: 4,
        mt: 40,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={4} sm={4}>
            <Typography
              variant="h6"
              sx={{
                "@media (max-width: 800px)": {
                  fontSize: "1rem", // Adjust font size for small screens

                  mb: "1rem", // Adjust margin for small screens
                },
              }}
              gutterBottom
            >
              Company
            </Typography>
            <Link
              href="/"
              sx={{
                "@media (max-width: 800px)": {
                  fontSize: "0.8rem", // Adjust font size for small screens

                  mb: "1rem", // Adjust margin for small screens
                },
              }}
              color="inherit"
              underline="none"
            >
              Home
            </Link>
            <br />
            <Link
              sx={{
                "@media (max-width: 800px)": {
                  fontSize: "0.8rem", // Adjust font size for small screens

                  mb: "1rem", // Adjust margin for small screens
                },
              }}
              href="/about"
              color="inherit"
              underline="none"
            >
              About
            </Link>
            <br />
            <Link
              sx={{
                "@media (max-width: 800px)": {
                  fontSize: "0.8rem", // Adjust font size for small screens

                  mb: "1rem", // Adjust margin for small screens
                },
              }}
              href="/services"
              color="inherit"
              underline="none"
            >
              Services
            </Link>
            <br />
            <Link
              sx={{
                "@media (max-width: 800px)": {
                  fontSize: "0.8rem", // Adjust font size for small screens

                  mb: "1rem", // Adjust margin for small screens
                },
              }}
              href="/contact"
              color="inherit"
              underline="none"
            >
              Contact
            </Link>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Typography
              sx={{
                "@media (max-width: 800px)": {
                  fontSize: "1rem", // Adjust font size for small screens

                  mb: "1rem", // Adjust margin for small screens
                },
              }}
              variant="h6"
              gutterBottom
            >
              Legal
            </Typography>
            <Link
              sx={{
                "@media (max-width: 800px)": {
                  fontSize: "0.8rem", // Adjust font size for small screens

                  mb: "1rem", // Adjust margin for small screens
                },
              }}
              href="/privacy-policy"
              color="inherit"
              underline="none"
            >
              Privacy Policy
            </Link>
            <br />
            <Link
              sx={{
                "@media (max-width: 800px)": {
                  fontSize: "0.8rem", // Adjust font size for small screens

                  mb: "1rem", // Adjust margin for small screens
                },
              }}
              href="/terms-of-service"
              color="inherit"
              underline="none"
            >
              Terms of Service
            </Link>
            <br />
            <Link
              sx={{
                "@media (max-width: 800px)": {
                  fontSize: "0.8rem", // Adjust font size for small screens

                  mb: "1rem", // Adjust margin for small screens
                },
              }}
              href="/disclaimer"
              color="inherit"
              underline="none"
            >
              Disclaimer
            </Link>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Typography
              sx={{
                "@media (max-width: 800px)": {
                  fontSize: "1rem", // Adjust font size for small screens

                  mb: "1rem", // Adjust margin for small screens
                },
              }}
              variant="h6"
              gutterBottom
            >
              Follow Us
            </Typography>
            <IconButton href="https://www.facebook.com" color="inherit">
              <Facebook />
            </IconButton>
            <IconButton href="https://www.twitter.com" color="inherit">
              <Twitter />
            </IconButton>
            <IconButton href="https://www.instagram.com" color="inherit">
              <Instagram />
            </IconButton>
            <IconButton href="https://www.linkedin.com" color="inherit">
              <LinkedIn />
            </IconButton>
          </Grid>
        </Grid>
        <Box mt={4} textAlign="center">
          <Typography variant="body2" color="inherit">
            &copy; {new Date().getFullYear()} CAMPS U. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;

import React from "react";
import { Card, CardContent, Skeleton, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";

const StyledLoadingCard = styled(Card)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
}));

const ImageContainer = styled(Box)({
  position: "relative",
  overflow: "hidden",
});

const OverlayText = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  width: "100%",
  color: "white",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  textAlign: "center",
  padding: theme.spacing(1),
}));

const CategoryCardLoading = () => {
  return (
    <StyledLoadingCard>
      <ImageContainer>
        <Skeleton variant="rectangular" width="100%" height={200} />
        <OverlayText>
          <Typography variant="h6" component="div">
            Loading...
          </Typography>
        </OverlayText>
      </ImageContainer>
    </StyledLoadingCard>
  );
};

export default CategoryCardLoading;

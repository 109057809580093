import React from "react";
import { Card, CardContent, CardMedia, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

const StyledCard = styled(Card)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  cursor: "pointer",
  "&:hover img": {
    transform: "scale(1.05)",
  },
}));

const ImageContainer = styled(Box)({
  position: "relative",
  overflow: "hidden",
  "& img": {
    transition: "transform 0.3s ease-in-out",
  },
});

const OverlayText = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  width: "100%",
  color: "white",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  textAlign: "center",
  padding: theme.spacing(1),
}));

const CategoryCard = ({ category }) => {
  const navigate = useNavigate();
  return (
    <StyledCard onClick={() => navigate("/camps/?category=" + category.name)}>
      <ImageContainer>
        <CardMedia
          component="img"
          image={
            category.image ||
            "https://thumbs.dreamstime.com/b/edged-burned-paper-14366437.jpg"
          }
          alt={category.name}
          sx={{
            height: {
              xs: "140px", // Height for extra-small screens
              sm: "180px", // Height for small screens
              md: "200px", // Height for medium screens
            },
            width: "100%", // Make sure the image takes full width of its container
          }}
        />
        <OverlayText>
          <Typography variant="h6" component="div">
            {category.name}
          </Typography>
        </OverlayText>
      </ImageContainer>
    </StyledCard>
  );
};

export default CategoryCard;

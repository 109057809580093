import { Box, Grid, Typography } from "@mui/material";
import CategoryCard from "components/CategoryCard";
import CategoryCardLoading from "components/CategoryCardLoading";
import Wrapper from "components/Wrapper";
import { FeaturedCamps } from "containers/FeaturedCamps";
import { FindCampsNearYou } from "containers/FindCampsNearYou";
import GettingStarted from "containers/GettingStarted";
import React, { useEffect, useState } from "react";
import { makeGetRequest } from "utils/api";
import { urls } from "utils/urls";

export const HomePage = () => {
  const [loading, setLoading] = useState(false);
  const [categoriesList, setCategoriesList] = useState({});

  useEffect(() => {
    fetchCategorys();
  }, []);
  const fetchCategorys = async () => {
    setLoading(true);
    const response = await makeGetRequest(urls.category, {}, false);
    if (response.status === 200) {
      setCategoriesList(response.data);
    }
    setLoading(false);
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: {
            xs: "20vh", // 50% of viewport height on extra-small screens
            sm: "40vh", // 60% of viewport height on small screens
            md: "60vh", // 70% of viewport height on medium screens
            lg: "80vh", // 80% of viewport height on large screens
          },
          backgroundImage: `url(/bg.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center", // Adjust to focus on the top of the image
          // mb: "2rem",
        }}
      />
      <FindCampsNearYou categoriesList={categoriesList} loading={loading} />

      <GettingStarted />

      <FeaturedCamps />
    </>
  );
};

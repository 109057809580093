import { Box, Grid } from "@mui/material";
import CampCard from "components/CampCard";
import CategoryCardLoading from "components/CategoryCardLoading";
import { CustomGrow } from "components/CustomGrow";
import FullPageLoader from "components/FullPageLoader";
import Wrapper from "components/Wrapper";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { makeGetRequest } from "utils/api";
import { urls } from "utils/urls";

export const MyCampsPage = () => {
  const [campsList, setCampsList] = useState({});
  const [favouriteCamps, setFavouriteCamps] = useState([]);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    fetchCamps();
    fetchFavouriteMinCamps();
  }, []);

  useEffect(() => {
    fetchCamps();
  }, [searchParams]);

  const fetchCamps = async () => {
    setLoading(true);
    const response = await makeGetRequest(
      urls.camp + "?my=1",
      {},
      isAuthenticated
    );
    if (response.status === 200) {
      setCampsList(response.data);
    }
    setLoading(false);
  };

  const fetchFavouriteMinCamps = async () => {
    setLoading(true);
    const response = await makeGetRequest(
      urls.favouriteMin,
      {},
      isAuthenticated
    );
    if (response.status === 200) {
      setFavouriteCamps(response.data);
    }
    setLoading(false);
  };
  return (
    <>
      <FullPageLoader loading={loading} />
      <Box
        sx={{
          width: "100%",
          height: {
            xs: "30vh", // 50% of viewport height on extra-small screens
            sm: "40vh", // 60% of viewport height on small screens
            md: "50vh", // 70% of viewport height on medium screens
            lg: "50vh", // 80% of viewport height on large screens
          },
          backgroundImage:
            "url(https://images.pexels.com/photos/773063/pexels-photo-773063.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          mb: "2rem",
        }}
      />
      <Wrapper>
        <h1 style={{ textAlign: "center" }}>My CAMPS!</h1>
        <Grid container justifyContent={"center"} spacing={3}>
          {loading
            ? // Render multiple LoadingCard components while loading
              Array(8)
                .fill(null)
                .map((_, index) => (
                  <Grid item key={index} xs={12} sm={6} md={3}>
                    <CategoryCardLoading />
                  </Grid>
                ))
            : campsList.results?.map((v, index) => (
                <Grid key={v.slug} item xs={12} sm={6} md={4} lg={3}>
                  <CustomGrow index={index + 1}>
                    <CampCard
                      {...v}
                      myCamp={true}
                      favourite={favouriteCamps.includes(v.id)}
                      setFavouriteCamps={setFavouriteCamps}
                    />
                  </CustomGrow>
                </Grid>
              ))}
        </Grid>
      </Wrapper>
    </>
  );
};

import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slice/user";

const reHydrateStore = () => {
  if (localStorage.getItem("token") !== null) {
    const token = JSON.parse(localStorage.getItem("token"));
    return {
      user: {
        token: token,
        isAuthenticated: !!token,
      },
    }; // re-hydrate the store
  }
  return { isAuthenticated: false };
};

const store = configureStore({
  reducer: {
    user: userSlice,
  },
  preloadedState: reHydrateStore(),
});

export default store;

import React from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { CustomBtn } from "components/CustomBtn";

const sections = [
  {
    title: "Membership",
    description:
      "Join our community and enjoy the benefits of our membership programs.",
    buttonText: "Learn More",
  },
  {
    title: "Organizer",
    description: "Become an organizer and manage your own camps and events.",
    buttonText: "Get Started",
  },
  {
    title: "Find Camp Job",
    description: "Looking for a camp job? Find exciting opportunities here.",
    buttonText: "Search Jobs",
  },
];

const GettingStarted = () => {
  return (
    <Box sx={{ flexGrow: 1, p: 4 }}>
      <Container>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ mb: 4, fontWeight: "bold" }}
        >
          Getting Started
        </Typography>
        <Grid container spacing={4}>
          {sections.map((section, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  textAlign: "center",
                  p: 2,
                  backgroundImage:
                    "linear-gradient(353deg, rgba(131,129,129,1) 0%, rgba(194,180,161,1) 51%)",

                  borderRadius: "15px",
                  boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
                  color: "#fff",
                }}
              >
                <CardContent>
                  <Typography variant="h4" gutterBottom>
                    {section.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {section.description}
                  </Typography>
                  <CustomBtn
                    name={section.buttonText}
                    sx={{
                      mt: 2,
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default GettingStarted;

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: '"Merriweather","Roboto", "sans-serif"',
  },
  palette: {
    primary: {
      main: "#001f3f", // Replace with your desired color (e.g., "#FF0000" for red)
    },
    secondary: {
      main: "#e53935",
    },
  },
});

export default theme;

/**
 *
 * Form
 *
 */

import React from "react";
import styled from "styled-components";

import { CircularProgress } from "@mui/material";

const CustomForm = styled.form`
  position: relative;
  margin-bottom: 0;
`;

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: -2px;
  height: 100%;
  width: calc(100% + 4px);
  z-index: 2;
`;

function Form(props) {
  return (
    <CustomForm
      style={props.overflow ? { overflow: props.overflow } : {}}
      {...props.formProps}
    >
      {props.isLoading ? (
        <Div>
          <CircularProgress />
        </Div>
      ) : (
        ""
      )}
      {props.children}
    </CustomForm>
  );
}

export default Form;

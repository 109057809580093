import { FormControl, FormHelperText, InputLabel, Select } from "@mui/material";
import { Controller } from "react-hook-form";

const CustomSelectField = ({
  name,
  label,
  control,
  defaultValue,
  helperText,
  children,
  multiple,
  ...props
}) => {
  const labelId = `${name}-label`;
  return (
    <FormControl color="secondary" size="small" fullWidth {...props}>
      <InputLabel shrink={true} id={labelId}>
        {label}
      </InputLabel>
      <Controller
        render={({ field }) => (
          <Select
            labelId={labelId}
            label={label}
            {...field}
            multiple={multiple || false}
            displayEmpty
            notched={true}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                  width: 250,
                },
              },
            }}
          >
            {children}
          </Select>
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />

      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
export default CustomSelectField;

import {
  Divider,
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Rating,
} from "@mui/material";
import FullPageLoader from "components/FullPageLoader";
import Wrapper from "components/Wrapper";
import { FavoriteCamps } from "containers/CampsPage/FavouriteCamps";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeGetRequest } from "utils/api";
import { urls } from "utils/urls";

export const CamperDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    fetchCamperDashboard();
  }, []);

  const fetchCamperDashboard = async () => {
    const response = await makeGetRequest(urls.camperDashboard, {}, true);
    if (response.status === 200) {
      setData(response.data);
    }
    setLoading(false);
  };
  return (
    <Wrapper>
      <FullPageLoader loading={loading} />
      <Grid container spacing={10} mt={3} justifyContent={"center"}>
        <Grid item xs={12} sm={6} md={4}>
          <Box bgcolor="white" borderRadius={"10px"}>
            <h2
              style={{
                textAlign: "center",
                background: "#001f3f",
                color: "white",
                padding: "15px",
                borderRadius: "10px 10px 0 0",
              }}
            >
              Attended Camps
            </h2>
            <List
              sx={{
                height: "400px",
                overflow: "scroll",
              }}
            >
              {data.attended_camps?.map((v, i) => (
                <React.Fragment key={v.id}>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => navigate("/camp/" + v.camp__slug)}
                    >
                      <ListItemText
                        primary={v.camp__title}
                        secondary={
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            <span>{v.camp__category__name}</span>
                            <Rating
                              name="half-rating-read"
                              defaultValue={v.camp__rating}
                              precision={0.5}
                              readOnly
                              size="small"
                            />
                          </Box>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
              {data.attended_camps?.length === 0 && (
                <ListItem sx={{ justifyContent: "center" }} disablePadding>
                  No Camps!
                </ListItem>
              )}
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box bgcolor="white" borderRadius={"10px"}>
            <h2
              style={{
                textAlign: "center",
                background: "#001f3f",
                color: "white",
                padding: "15px",
                borderRadius: "10px 10px 0 0",
              }}
            >
              Registered Camps
            </h2>
            <List
              sx={{
                height: "400px",
                overflow: "scroll",
              }}
            >
              {data.registered_camps?.map((v, i) => (
                <React.Fragment key={v.id}>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => navigate("/camp/" + v.camp__slug)}
                    >
                      <ListItemText
                        primary={v.camp__title}
                        secondary={
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            <span>{v.camp__category__name}</span>
                            <Rating
                              name="half-rating-read"
                              defaultValue={v.camp__rating}
                              precision={0.5}
                              readOnly
                              size="small"
                            />
                          </Box>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
              {data.registered_camps?.length === 0 && (
                <ListItem sx={{ justifyContent: "center" }} disablePadding>
                  No Camps!
                </ListItem>
              )}
            </List>
          </Box>
        </Grid>
      </Grid>

      <FavoriteCamps />

      {/* TODO: Recently viewed camps */}
    </Wrapper>
  );
};
